<template>
  <div class="home">
    <v-container fluid>
      <v-layout>
        <v-navigation-drawer
            style="height: 100%"
            permanent
        >
          <v-list>
            <v-list-item @click="screen='ExitInfo'">
              Exiting Information
            </v-list-item>
            <v-list-item @click="screen=''">
              List Of Penalties
            </v-list-item>
            <v-list-item @click="screen=''">
              Conditional
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main v-if="screen!=null" style="max-width: 85%" class="ml-5">
          <ExitingInfo :evaluation="this.evaluation" v-if="screen=='ExitInfo'"></ExitingInfo>
        </v-main>
      </v-layout>
    </v-container>



  </div>
</template>

<script>
import ExitingInfo from "@/views/ExitingAndEnforcement/ExitingInfo";
import {evalApi, globalConfig} from "@/plugins/axios";

export default {
  components: {ExitingInfo},

  created() {
    const evalId = this.$route.params.evaluationId;
    this.loadData(evalId)
  },

  data() {
    return {
      evaluation: {},
      screen:'ExitInfo'
    }
  },

  methods: {

    loadData(id) {
      evalApi.post(`/evaluationsImport/getEvaluation`, {evaluationId: id}, globalConfig)
          .then((res) => {
            this.evaluation = res.data
            console.log("TEST1")
            console.log(this.evaluation)
      })
    }

  }

}
</script>

<style>

.v-input--radio-group legend.v-label {
  font-size: 18px;
  font-weight: bold;
}

.v-input__control {
  padding-top: 0px;
  padding-bottom: 0px;
}

</style>